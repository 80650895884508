import { useEffect, useState } from "react";
import Layout from "layout/Layout";
import LogoIllustrationSVG from "svg/illustrations/LogoIllustrationSVG";
import { useTranslation } from "react-i18next";
import { Loader } from "UI/Loader/Loader";
import styles from "./MainPage.module.scss";
import Hash from "components/Hash/Hash";
import { useNavigate } from "react-router-dom";
import FoundPaymentIllustrationSVG from "svg/illustrations/FoundPaymentIllustrationSVG";
import useTokenStore from "store/TokenStore";
import useDeviceStore from "store/DeviceStore";
import { getData, getDeviceInfo } from "common/commonFunctions";
import useErrorStore from "store/errorStore";
import useUrlData from "store/DataStore";

const MainPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToken} = useTokenStore();
  const [localToken,setLocalToken] = useState('')
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(<LogoIllustrationSVG height="200" width="200" />);
  const [loadingText, setLoadingText] = useState(t("Загружаем страницу платежа"));
  const [urlParams,setUrlParams] = useState('')
  const { setDevice } = useDeviceStore();
  const { networkErr } = useErrorStore((state:any) => state);
  const setData = useUrlData((state:any) => state.setData);


  useEffect(() => {
    const fetchData = async () => {
      const url = window.location.href;
      const paramsString = url.substring(url.lastIndexOf("/") + 1);
      if (paramsString) {
        const data = await getData(paramsString);
        if (data) {
          setData(data);
        }
      } else {
        console.error("Invalid parameter in URL");
      }
    };
  
    fetchData();
    
  }, [setData]);

  useEffect(() => {
    getDeviceInfo(setDevice);
  }, [networkErr]);

  useEffect(() => {
    setLoading(true);
    const firstTimerId = setTimeout(() => {
      setLoadingText(t("Ищем подходящий реквизит"));
      setImg(<FoundPaymentIllustrationSVG height="200" width="200" />);
    }, 2000);

    const secondTimerId = setTimeout(() => {
      const url = window.location.href;
      const paramsString = url.substring(url.lastIndexOf("/") + 1);
      
      paramsString === 'qr' ? navigate("/method") : navigate("/payment");
    }, 5000);

    return () => {
      setLoading(false);
      clearTimeout(firstTimerId);
      clearTimeout(secondTimerId);
    };
  }, [navigate, t, networkErr]);

  return (
    <Layout headerText={t("Загрузка и поиск реквизитов")} currentStep={0} loading={loading}>
      <div className={styles.up}>
        <Hash />
        <Loader />
      </div>
      <div className={styles.content}>
        {img}
        <div className={styles.title}>{loadingText}</div>
      </div>
    </Layout>
  );
};

export default MainPage;