import styles from "./Canceled.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "layout/Layout";
import Hash from "components/Hash/Hash";
import CancelIllustrationSVG from "svg/illustrations/CancelIllustrationSVG";
import { CancelPaymentSVG } from "svg/icons/CancelPaymentSVG";
import { Modal } from "UI/Modal/Modal";
import useDeviceStore from "store/DeviceStore";
import { getDeviceInfo } from "common/commonFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import useErrorStore from "store/errorStore";
import useTokenStore from "store/TokenStore";
import useUrlData from "store/DataStore";
import { Loader } from "UI/Loader/Loader";

const Canceled = () => {
  const { t } = useTranslation();
  const [onAccept, setOnAccept] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const allData = useUrlData((state) => state.data);
  const { setToken } = useTokenStore();
  const { setDevice } = useDeviceStore();
  const { networkErr } = useErrorStore((state) => state);
  const [view, setView] = useState('CANCELED')
  
  useEffect(() => {
    getDeviceInfo(setDevice);
  }, [allData,networkErr]);
  
  return (
    <>
    <select style={{position:'absolute',top: '0px',left: '0px'}} className={styles.select} value={view} onChange={(e:any) => setView(e.target.value)}>
      <option value="">Что-то пошло не так</option>
      <option defaultChecked value="CANCELED">Платеж отменен</option>
      <option value="NETWORKERR">Ошибка подключения к сети</option>
      <option value="TIMEISUP">Время на оплату истекло</option>
      <option value="NOPAYMENT">Платеж не найден</option>
    </select>
    <Layout
      headerText={''}
      headerStep={location?.state === 'NETWORKERR' ? t("Ошибка подключения к сети") :t("3.Платеж отменен.")}
      currentStep={3}
    >
      <div className={styles.wrapper}>
        <div className={styles.up}>
          <Hash />

          <CancelPaymentSVG width="42" height="42" />
        </div>

        <div className={styles.content}>
          <CancelIllustrationSVG width="175" height="200" />

          {view === 'CANCELED' &&
            <>
              <p>{t("Платеж отменен")}</p>
              <span>{t("Новую заявку на оплату вы можете создать на сайте.")}</span>
            </>
          }

          {view === 'NETWORKERR' &&
            <>
              <p>{t("Ошибка подключения к сети")}</p>
              <span>{t("Попробуйте еще раз позже")}</span>
            </>
          }

          {view === 'TIMEISUP' &&
            <>
              <p>{t("Время на оплату истекло. Вы можете создать новую заявку на оплату.")}</p>
            </>
          }

          {view === 'NOPAYMENT' &&
            <>
              <p>{t("Платеж не найден")}</p>
            </>
          }

          {view === '' && 
            <>
              <p>{t("Что-то пошло не так")}</p>
              <span>{t("Попробуйте еще раз позже")}</span>
            </>
          }
          
        </div>
      </div>
      {onAccept && <Modal type="accept" onClose={() => setOnAccept(false)} />}
    </Layout>
    </>
  );
};

export default Canceled;