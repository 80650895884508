export function getDeviceInfo(setDevice: (item:string) => void) {
    const userAgent = navigator.userAgent;
    if (/mobile/i.test(userAgent)) {
        setDevice('mobile');
    } else if (/tablet/i.test(userAgent)) {
        setDevice('tablet');
    } else {
        setDevice('pc');
    }
}
export function getData(num:string): any{
    if(num === '1'){
        return {
                    payment_id: "PAY123456789",
                    amount: 1500,
                    bank_name: "Альфабанк",
                    bank_card_number: null,
                    phone_number: "+79998887766",
                    account_number: null,
                    bic: null,
                    full_name: "John Doe",
                    transfer_method: "SBP",
                    status_name: 'new',
                    expires_in: 3600,
                    geo_code: "US-NY"
                }
    }else if(num === 'qr'){
        return {
            payment_id: "PAY123456789",
            amount: 2500,
            bank_name: "Сбербанк",
            bank_card_number: null,
            phone_number: "+79998887766",
            account_number: null,
            bic: null,
            full_name: "John Doe",
            transfer_method: "BANC_CARD",
            status_name: 'new',
            expires_in: 3600,
            geo_code: "US-NY"
        }
    }else if(num === 'acc'){
        return {
            payment_id: "PAY123456789",
            amount: 9500,
            bank_name: 'Тинькофф',
            bank_card_number: "1234321241231251",
            phone_number: null,
            account_number: "29314129529123152315",
            bic: "254120321",
            full_name: "John Doe",
            transfer_method: "ACCOUNT_NUMBER",
            status_name: 'new',
            expires_in: 3600,
            geo_code: "US-NY"
        }
    }else if(num === 'sbp'){
        return {
            payment_id: "PAY123456789",
            amount: 1500,
            bank_name: "Альфабанк",
            bank_card_number: null,
            phone_number: "+79998887766",
            account_number: null,
            bic: null,
            full_name: "John Doe",
            transfer_method: "SBP",
            status_name: 'new',
            expires_in: 3600,
            geo_code: "US-NY"
        }
    }else if(num === 'card'){
        return {
            payment_id: "PAY123456789",
            amount: 12500,
            bank_name: "ВТБ",
            bank_card_number: '124542315212422',
            phone_number: null,
            account_number: null,
            bic: null,
            full_name: "John Doe",
            transfer_method: "BANC_CARD",
            status_name: 'new',
            expires_in: 3600,
            geo_code: "US-NY"
        }
    }
}