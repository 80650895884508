import styles from "./searchpay.module.scss";
import { useEffect } from "react";
import Layout from "layout/Layout";
import Hash from "components/Hash/Hash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Loader } from "UI/Loader/Loader";
const SearchPay = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  

  useEffect(() => {
    setTimeout(() => {
        navigate('/success');
    }, 2000);
  },[navigate])


  return (
    <Layout
      headerText={''}
      headerStep={t("2. Оплата.")}
      currentStep={2}
    >
      <div className={styles.wrapper}>
        <div className={styles.up}>
          <Hash />
          <Loader />
        </div>

        <div className={styles.content}>

          <div className={styles.qrContainer}>
            <span className={styles.searchTitle}>
              {t("Ваш платеж в обработке")}
            </span>
            <span className={styles.searchText}>
              {t("Это может занять несколько минут")}
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SearchPay;