import styles from "./Payment.module.scss";
import { useEffect, useState } from "react";
import { Button } from "UI/Button/Button";
import Layout from "layout/Layout";
import Hash from "components/Hash/Hash";
import PayBlock from "components/PayBlock/PayBlock";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal } from "UI/Modal/Modal";
import TimerComponent from "components/Timer/Timer";
import useTimerData from "store/TimerStore";
import useUrlData from "store/DataStore";
import { Loader } from "UI/Loader/Loader";
import useTokenStore from "store/TokenStore";
import useDeviceStore from "store/DeviceStore";
import { getDeviceInfo } from "common/commonFunctions";
import useErrorStore from "store/errorStore";
import PlatiQr from "components/PlatiQr/PlatiQr";
import { formatValue } from "utils/formatValue";
import SberPaySVG from "svg/payMethods/SberPaySVG";

const Payment = () => {
  const timeLimit = useTimerData((state) => state.end);
  const allData = useUrlData((state) => state.data);
  const {t} = useTranslation()
  const amount = formatValue(allData.amount);
  const navigate = useNavigate()
  const [onCancel, setOnCancel] = useState(false)
  const [onPay, setOnPay] = useState(false)
  const { setToken } = useTokenStore();
  const { setDevice, device } = useDeviceStore();
  const { networkErr } = useErrorStore((state) => state);

useEffect(() => {
  console.log(allData);
},[allData])

  useEffect(() => {
    getDeviceInfo(setDevice);
  }, [allData, networkErr, setDevice]);

  return (
    <Layout
      headerText={t("Скопируйте реквизиты получателя")}
      headerStep={t("2. Оплата.")}
      currentStep={2}
    >
        <div className={styles.wrapper}>
          <div className={styles.up}>
            <Hash />
            <TimerComponent />
          </div>

          {allData.bank_name === 'Сбербанк' && allData.phone_number !== null  &&  device !== 'pc' ? 

          <div className={styles.qrpayBlock}>
            <div className={styles.mobileInfo}>
              <h3>{allData.amount} ₽</h3>
              <div>
                <p>{t("Получатель")}</p>
                <span>{allData.full_name}</span>
              </div>
            </div>
            <a href={`https://www.sberbank.com/sms/pbpn?requisiteNumber=[${allData.phone_number}]`}>
              <SberPaySVG height="60" width="372" className={styles.paySvg} />
            </a>
            <div className={styles.or}>
              <div />
                <span>{t('или')}</span>
              <div />
            </div>
          </div>
          :
          <></>}

        <div className={styles.content}>
          <div className={styles.info}>
            <div className={styles.value}>
            {t("Переведите {{amount}} ₽ по указанным реквизитам", { amount })}
            </div>
            <div className={styles.recipient}>
              <p>
                {t("Не оставляйте комментарий к переводу. Получатель")} {" "}
                <span>{allData.full_name}</span>
              </p>
            </div>
          </div>

          <PayBlock />

          <div className={styles.buttons}>
            <Button onClick={() => setOnPay(true)} variant="yellow">{t("Оплачено")}</Button>
            {allData.bank_name === 'Сбербанк' && allData.phone_number !== null && device === 'pc' &&
              <div className={styles.qrNavBtn} onClick={() => navigate("/qrpay")}>
                <PlatiQr />
              </div>
            }
            <Button variant="link" onClick={() => setOnCancel(true)}>{t("Отменить платеж")}</Button>
          </div>
        </div>
      </div>

      {onCancel && <Modal type="cancel" goTo={() => navigate("/cancelrequest")} onClose={() => setOnCancel(false)}/>}
      {onPay && <Modal type="attention" goTo={() => navigate("/searchPay")} onClose={() => setOnPay(false)}/>}

    </Layout>
  );
};

export default Payment;
